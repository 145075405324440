import React from "react";
import "./Loader.css"; // External CSS file

const Loader = ({loadingText, theme }) => {
    const textColor = theme === "dark" ? "#ffffff" : "#000000";

    return (
        <div className="loader">
        <span id="loading-text" style={{ color: textColor }}>{loadingText }</span>
        </div>
    );
};

export default Loader;